import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Post from '../components/Post'
import Sidebar from '../components/Sidebar'
import { Link } from 'gatsby'

class BlogList extends React.Component {
  render() {
    const items = []
    const { title, subtitle } = this.props.data.site.siteMetadata
    const posts = this.props.data.allMarkdownRemark.edges
    const {
      current,
      total,
    } = this.props.pageContext

    posts.forEach(post => {
      items.push(<Post data={post} key={post.node.fields.slug} />)
    })

    return (
      <Layout>
        <div>
          <Helmet>
            <title>{title}</title>
            <meta name="description" content={subtitle} />
          </Helmet>
          <div className="content">
            <div className="content__inner">{items}</div>
            
            <ul style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `center`,
              listStyle: `none`,
              paddingLeft: 30,
              paddingRight: 30,
              margin: `0 auto`,
            }}
            className="pagination">

                <li className={current === 1 ? 'disabled' : ''}>
                  <Link to={'/'}>1</Link>
                </li>
                <li id="pagination-dot1" className={current > 3 ? '' : 'disabled'}>
                  ...
                </li>
                <li className={current === 1 || current === 2 ? 'disabled' : ''}>
                  <Link to={`/page/${current -1}`}>{current-1}</Link>
                </li>
                <li style={{backgroundColor:`#5d93ff`}}>
                  <Link style={{color:`white`}} to={`/page/${current}`}>{current}</Link>
                </li>
                <li className={current === total || current === total - 1 ? 'disabled' : ''}>
                  <Link to={`/page/${current +1}`}>{current+1}</Link>
                </li>
                <li id="pagination-dot2" className={current < total - 2 ? '' : 'disabled'}>
                  ...
                </li>
                <li className={current === total ? 'disabled' : ''}>
                  <Link to={`/page/${total}`}>{total}</Link>
                </li>
            </ul>
          </div>
          <Sidebar {...this.props} />
        </div>
      </Layout>
    )
  }
}

export default BlogList

export const pageQuery = graphql`
 
  query blogListQuery($skip: Int!, $limit: Int!){
    site {
      siteMetadata {
        title
        subtitle
        copyright
        menu {
          label
          path
        }
        author {
          name
          twitter
          github
        }
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: $limit
      filter: { frontmatter: { layout: { eq: "post" }, draft: { ne: true } } }
      skip: $skip
      ) {
      edges {
        node {
          fields {
            slug
            tagSlugs
            categorySlug
          }
          frontmatter {
            title
            featuredImage {
              childImageSharp {
                sizes(maxWidth: 630) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
            tags
            path
            date
            category
            description
          }
        }
      }
    }
  }
`
